@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@flaticon/flaticon-uicons/css/all/all";
@import "./layout.css";

:root {
  --desktop-breakpoint: 1024px;
  --tablet-breakpoint: 768px;
  --mobile-breakpoint: 480px;
  --container-width: 1400px;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

:root {
  font-size: 16px;
  /* default */
}

@media (min-width: 1024px) {
  :root {
    font-size: 20px;
    /* Above desktop */
  }
}

@media (max-width: 1024px) {
  :root {
    font-size: 14px;
    /* Font size desktop */
  }
}

@media (max-width: 768px) {
  :root {
    font-size: 12px;
    /* Font size tablet */
  }
}

@media (max-width: 480px) {
  :root {
    font-size: 10px;
    /* Font size mobile */
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--white-smoke);
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.125rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

p {
  font-size: 0.75rem;
  margin: 0;
  font-weight: 500;
}

small {
  font-size: 0.7rem;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 0.875rem;
  font-family: Montserrat, sans-serif;
}

ul {
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

i {
  position: relative;
  bottom: -1.75px;
}

label {
  pointer-events: none;
}

input,
textarea {
  font-family: Montserrat, sans-serif;
  font-size: inherit;
}

.scrollbar::-webkit-scrollbar {
  width: 0.12rem;
}

.scrollbar::-webkit-scrollbar-track {
  background: #ffffff00;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #a6a6a6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-base {
  font-size: 0.9rem;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.shadow-container {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
}
