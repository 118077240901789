/* Overview Page */
.overview-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); /* Default 2 columns */
  gap: 0.5rem; /* Gap between columns */
}

/* Medium screens (≥640px) */
@media (min-width: 640px) {
  .overview-container {
    grid-template-columns: repeat(4, minmax(0, 1fr)); /* 4 columns */
  }
}

/* Large screens (≥1024px) */
@media (min-width: 1024px) {
  .overview-container {
    grid-template-columns: repeat(12, minmax(0, 1fr)); /* 12 columns */
  }
}

/* Extra large screens (≥1280px) */
@media (min-width: 1280px) {
  .overview-container {
    grid-template-columns: repeat(16, minmax(0, 1fr)); /* 16 columns */
  }
}

.column-first-overview {
  grid-column: span 2 / span 2; /* Default 2 columns */
}

@media (min-width: 640px) {
  .column-first-overview {
    grid-column: span 4 / span 4; /* Spans 4 columns */
  }
}

@media (min-width: 1024px) {
  .column-first-overview {
    grid-column: span 5 / span 5; /* Spans 5 columns */
  }
}

@media (min-width: 1280px) {
  .column-first-overview {
    grid-column: span 7 / span 7; /* Spans 7 columns */
  }
}

.column-second-overview {
  grid-column: span 1 / span 1; /* Default 1 column */
}

@media (min-width: 640px) {
  .column-second-overview {
    grid-column: span 2 / span 2; /* Spans 2 columns */
  }
}

@media (min-width: 1024px) {
  .column-second-overview {
    grid-column: span 4 / span 4; /* Spans 4 columns */
  }
}

@media (min-width: 1280px) {
  .column-second-overview {
    grid-column: span 5 / span 5; /* Spans 5 columns */
  }
}

.column-third-overview {
  grid-column: span 1 / span 1; /* Default 1 column */
}

@media (min-width: 640px) {
  .column-third-overview {
    grid-column: span 2 / span 2; /* Spans 2 columns */
  }
}

@media (min-width: 1024px) {
  .column-third-overview {
    grid-column: span 3 / span 3; /* Spans 3 columns */
  }
}

@media (min-width: 1280px) {
  .column-third-overview {
    grid-column: span 4 / span 4; /* Spans 4 columns */
  }
}

  
  /* Categories Addition */
  .addition-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem; /* assuming 4 means 1rem gap */
    row-gap: 1rem;
  }
  @media (min-width: 1024px) {
    .addition-container {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  
  .column-full-addition {
    grid-column: span 1 / span 1;
  }
  @media (min-width: 1024px) {
    .column-full-addition {
      grid-column: span 4 / span 4;
    }
  }
  
  .column-first-addition {
    grid-column: span 1 / span 1;
  }
  @media (min-width: 1024px) {
    .column-first-addition {
      grid-column: span 3 / span 3;
    }
  }
  
  .column-second-addition {
    grid-column: span 1 / span 1;
  }
  @media (min-width: 1024px) {
    .column-second-addition {
      grid-column: span 1 / span 1;
    }
  }
  