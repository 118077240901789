.card-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cover {
  position: relative;
}

.cover.fill {
  width: 100%;
  height: 100%;
}

.card-overlay.fill {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.detail.fill {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  text-align: center;
}

.cover-image {
  max-width: 100%;
  height: auto;
}
